<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学员档案</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom:10px">
            <div title="学员姓名" class="searchboxItem ci-full">
              <span class="itemLabel">学员姓名:</span>
              <el-input
                class="listInput"
                v-model="searchData.userName"
                placeholder="请输入学员姓名"
                size="small"
                clearable
              />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input
                class="listInput"
                v-model="searchData.idcard"
                placeholder="请输入身份证号"
                size="small"
                clearable
              />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input
                class="listInput"
                v-model="searchData.mobile"
                placeholder="请输入手机号"
                size="small"
                clearable
              />
            </div>
            <div title="证书编号" class="searchboxItem ci-full">
              <span class="itemLabel">证书编号:</span>
              <el-input
                class="listInput"
                v-model="searchData.CertificateNo"
                placeholder="请输入证书编号"
                size="small"
                clearable
              />
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:10px">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input
                class="listInput"
                v-model="searchData.projectCode"
                placeholder="请输入班级编码"
                size="small"
                clearable
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                class="listInput"
                v-model="searchData.projectName"
                placeholder="请输入班级名称"
                size="small"
                clearable
              />
            </div>
            <div title="合格日期" class="searchboxItem ci-full">
              <span class="itemLabel">合格日期:</span>
              <el-date-picker
                v-model="searchData.qualifiedTime"
                type="daterange"
                size="small"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
          </div>
          <div class="searchbox">
            <div title="劵名称" class="searchboxItem ci-full">
              <span class="itemLabel">劵名称:</span>
              <el-input
                class="listInput"
                v-model="searchData.couponName"
                placeholder="请输入劵名称"
                size="small"
                clearable
              />
            </div>
             <div title="学员类型" class="searchboxItem ci-full itemLabel_study">
          <span class="itemLabel">学员类型:</span>
          <el-select
            v-model="searchData.studentType"
            placeholder="请选择学员类型"
            clearable
            size="small"
          >
            <el-option
              v-for="item in studentType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
            <div title="领劵日期" class="searchboxItem ci-full">
              <span class="itemLabel">领劵日期:</span>
              <el-date-picker
                v-model="searchData.collectCouponsTime"
                type="daterange"
                size="small"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div class="df" style="padding:0 15px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="downloadStudentRoster()"
                >下载学员花名册</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="batchDownloadStudentFiles()"
                >批量下载学员档案</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="学员姓名"
                align="center"
                show-overflow-tooltip
                prop="userName"
                minWidth="100"
              />
              <el-table-column
                label="身份证号"
                align="center"
                show-overflow-tooltip
                prop="idcard"
                minWidth="180"
              />
              <el-table-column
                label="手机号"
                align="center"
                show-overflow-tooltip
                prop="mobile"
                minWidth="120"
              />
              <el-table-column
                label="班级编码"
                align="center"
                show-overflow-tooltip
                prop="projectCode"
                minWidth="150"
              />
              <el-table-column
                label="班级名称"
                align="center"
                show-overflow-tooltip
                prop="projectName"
                minWidth="120"
              />
              <el-table-column
                label="证书编号"
                align="center"
                show-overflow-tooltip
                prop="shanxiUserCertFront"
                minWidth="150"
              />
              <el-table-column
                label="完成学时"
                align="center"
                show-overflow-tooltip
                prop="studyLessonNum"
              />
              <el-table-column
                label="劵名称"
                align="center"
                show-overflow-tooltip
                minWidth="150"
                prop="shanxiCouponName"
              />
              <el-table-column
                label="劵编号"
                align="center"
                show-overflow-tooltip
                 minWidth="150"
                prop="shanxiCouponCode"
              />
              <el-table-column
                label="领劵日期"
                align="center"
                show-overflow-tooltip
                prop="shanxiUserStart"
                minWidth="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.shanxiUserStart | momentDate }}
                </template>
              </el-table-column>
               <el-table-column
                label="学员类型"
                align="center"
                show-overflow-tooltip
                 minWidth="150"
                prop="studentType"
              >
              <template slot-scope="scope">
                {{$setDictionary('STUDENT_TYPE_SHANXI',scope.row.studentType)}}
              </template>
               </el-table-column>
              <el-table-column
                label="合格日期"
                align="center"
                show-overflow-tooltip
                prop="shanxiUserCertTime"
                minWidth="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.shanxiUserCertTime | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="200px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    @click="
                      lookCertificate(
                        scope.row.userId,
                        scope.row.projectId,
                        'pdf'
                      )
                    "
                    >合格证预览</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style=""
                    @click="
                      lookArchives(scope.row.userId, scope.row.projectId, 'pdf')
                    "
                    >档案预览</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      class="dialog2"
      :title="dialogTitle"
      :visible.sync="dialog1"
      width="50%"
      top="15px"
      center
    >
      <div id="pdf-content" style="height: 800px" v-if="stu == 'pdf'"></div>
    </el-dialog>
  </div>
</template>
<script>
const className = "eduCertService";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
export default {
  name: "trainingManagement/TrainingRecordsSee",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      searchData: {
        userName: "", //姓名
        idcard: "", //身份证号
        mobile: "", //手机号
        CertificateNo: "", //证书编号
        projectCode: "", //班级编码
        projectName: "", //班级名称
        qualifiedTime: "", //合格时间
        collectCouponsTime: "", //领劵时间
        couponName:'', //卷名称
        studentType:'',
      },
      dialog1: false,
      ImgSrc: "",
      dialogTitle: "",
      studentType:[]
    };
  },
  created() {
    this.getStudent()
    this.getTableHeight();
  },
  watch: {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {},
  methods: {
    //下载学员花名册
    downloadStudentRoster() {
      const params = {};
      if (this.searchData.userName) {
        params.userName = this.searchData.userName;
      }
      if (this.searchData.idcard) {
        params.idcard = this.searchData.idcard;
      }
      if (this.searchData.mobile) {
        params.mobile = this.searchData.mobile;
      }
      if (this.searchData.CertificateNo) {
        params.shanxiUserCertFront = this.searchData.CertificateNo;
      }
      if (this.searchData.projectCode) {
        params.projectCode = this.searchData.projectCode;
      }
      if (this.searchData.projectName) {
        params.projectName = this.searchData.projectName;
      }
      if (this.searchData.studentType) {
        params.studentType = this.searchData.studentType;
      }
      if (this.searchData.couponName) {
        params.couponName = this.searchData.couponName;
      }
      if (this.searchData.qualifiedTime) {
        params.shanxiUserCertTimeStart = this.searchData.qualifiedTime[0];
        params.shanxiUserCertTimeEnd = this.searchData.qualifiedTime[1];
      }
      if (this.searchData.collectCouponsTime) {
        params.ledTheStockDateStart = this.searchData.collectCouponsTime[0];
        params.ledTheStockDateEnd = this.searchData.collectCouponsTime[1];
      }
      this.$post("/biz/sou/shanxi/projectUser/downloadRoster", params).then(
        (ret) => {
          console.log(ret);
          if (ret.status == "0") {
            window.open(ret.data);
          }
        }
      );
    },
    //批量下载学员档案
    batchDownloadStudentFiles() {
      const params = {};
      if (this.searchData.userName) {
        params.userName = this.searchData.userName;
      }
      if (this.searchData.idcard) {
        params.idcard = this.searchData.idcard;
      }
      if (this.searchData.mobile) {
        params.mobile = this.searchData.mobile;
      }
      if (this.searchData.CertificateNo) {
        params.shanxiUserCertFront = this.searchData.CertificateNo;
      }
      if (this.searchData.projectCode) {
        params.projectCode = this.searchData.projectCode;
      }
      if (this.searchData.projectName) {
        params.projectName = this.searchData.projectName;
      }
      if (this.searchData.couponName) {
        params.couponName = this.searchData.couponName;
      }
      if (this.searchData.studentType) {
        params.studentType = this.searchData.studentType;
      }
      if (this.searchData.qualifiedTime) {
        params.shanxiUserCertTimeStart = this.searchData.qualifiedTime[0];
        params.shanxiUserCertTimeEnd = this.searchData.qualifiedTime[1];
      }
      if (this.searchData.collectCouponsTime) {
        params.ledTheStockDateStart = this.searchData.collectCouponsTime[0];
        params.ledTheStockDateEnd = this.searchData.collectCouponsTime[1];
      }
      this.$post("/biz/sou/shanxi/projectUser/download/file", params).then(
        (ret) => {
          console.log(ret);
          if (ret.status == "0") {
            if (!this.downloadItems.includes(ret.data.data.taskId)) {
              this.$store.dispatch("pushDownloadItems", ret.data.data.taskId);
            } else {
              this.$message.warning(
                "[" + ret.data.data.fileName + "]已经申请下载,请耐心等待"
              );
            }
          } else {
            this.$message.error(ret.data.message);
          }
        }
      );
    },
    //查看档案
    lookArchives(userId, projectId, stu) {
      this.stu = stu;
      this.dialogTitle = "查看档案";
      this.$post("/cert/downloadTrainDocument", { userId, projectId }).then(
        (ret) => {
          if (ret.status == 0) {
            this.dialog1 = true;
            this.$nextTick(() => {
              pdf.embed(ret.data, "#pdf-content");
            });
          } else {
            this.$message({
              message: "暂无档案查看",
              type: "error",
            });
          }
        }
      );
    },
    //查看合格证
    lookCertificate(userId, projectId, stu) {
      this.stu = stu;
      this.dialogTitle = "查看合格证";
      this.$post("/cert/downloadCert", { userId, projectId }).then((ret) => {
        if (ret.status == 0) {
          this.dialog1 = true;
          this.$nextTick(() => {
            pdf.embed(ret.data, "#pdf-content");
          });
        } else {
          this.$message({
            message: "暂无合格证",
            type: "error",
          });
        }
      });
    },
    getData(pageNum = 1) {
      const params = {
        // projectId: this.$route.query.projectId,
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.userName) {
        params.userName = this.searchData.userName;
      }
      if (this.searchData.idcard) {
        params.idcard = this.searchData.idcard;
      }
      if (this.searchData.mobile) {
        params.mobile = this.searchData.mobile;
      }
      if (this.searchData.CertificateNo) {
        params.shanxiUserCertFront = this.searchData.CertificateNo;
      }
      if (this.searchData.projectCode) {
        params.projectCode = this.searchData.projectCode;
      }
      if (this.searchData.projectName) {
        params.projectName = this.searchData.projectName;
      }
      if (this.searchData.studentType) {
        params.studentType = this.searchData.studentType;
      }
      if (this.searchData.couponName) {
        params.couponName = this.searchData.couponName;
      }
      if (this.searchData.qualifiedTime) {
        params.shanxiUserCertTimeStart = this.searchData.qualifiedTime[0];
        params.shanxiUserCertTimeEnd = this.searchData.qualifiedTime[1];
      }
      if (this.searchData.collectCouponsTime) {
        params.ledTheStockDateStart = this.searchData.collectCouponsTime[0];
        params.ledTheStockDateEnd = this.searchData.collectCouponsTime[1];
      }
      this.doFetch({
        url: "/biz/sou/shanxi/projectUser/pageList",
        params,
        pageNum,
      });
    },
    getStudent() {
       const studentList = this.$setDictionary("STUDENT_TYPE_SHANXI", "list");
        for (const key in studentList) {
        this.studentType.push({
          value: key,
          label: studentList[key],
        });
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scope>
// .el-table--small td, .el-table--small th {
//   padding: 0
// }
.el-table {
  .el-table__fixed-right {
    height: auto !important; // 此处的important表示优先于element.style
    bottom: 12px; // 改为自动高度后，设置与父容器的底部距离，则高度会动态改变
  }
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 64px;
  min-width: 64px;
  height: 82px;
  display: flex;
}

.el-upload-list.el-upload-list--picture-card {
  display: flex;
}
</style>
<style lang="less">
.hide .el-upload--picture-card {
  display: none;
}
</style>
